import { Form, Formik } from "formik";
import { equals } from "ramda";
import React, { useState } from "react";
import * as Yup from "yup";
import { Button } from "../../FormComponents/Button/Button";
import { Input } from "../../FormComponents/Input/Input";
import s from "../style/formStyle.module.scss";
import { AsyncSelect } from '../../FormComponents/AsyncSelect/AsyncSelect';
// import { DeleteIcon } from "../../../assets/DeleteIcon";
import { toolsOptions } from "./dataFeedbackForm";
import { InputNotEdit } from "../../FormComponents/InputNotEdit/InputNotEdit";
import { Label } from "@material-ui/icons";
import { TextArea } from '../../FormComponents/Input/TextArea';
import { InputFile } from "../../FormComponents/InputFile/InputFile";
import { InputFileReadOnly } from "../../FormComponents/InputFile/InputFileReadOnly";

type AddFeedbackFormT = {
	initValues: any;
	// defaultValue?: saveFacilitiesT
	onSubmit: any;
	onDeactivate?: any;
	onUpdate?: any;
	onReset?: any;
	type?: string;
	infoMember?: any;
	[key: string]: any;
};

const validationSchema = Yup.object({
	status: Yup.object().required('Status is a required field').nullable(),
});

export const AddFeedbackForm = ({ ...props }: AddFeedbackFormT) => {

	const [inputValue, onInputChange] = useState();
	const {
		initValues,
		onSubmit,
		// onDelete,
		type,
		infoMember,
		status,
	} = props;

	const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
	const isEdit = type === "edit";

	return (
		<Formik
			enableReinitialize
			initialValues={initValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			infoMember={infoMember}
		>
			{({ values, ...formikProps }) => {
				if (isEdit) {
					const defaultTest = {
						...initValues,
					};
					const similarObjects: boolean = equals(values, defaultTest);
					if (!similarObjects && !isSaveChangeParams)
						setIsSaveChangeParams(true);
					if (similarObjects && isSaveChangeParams)
						setIsSaveChangeParams(false);
				}
				return (
					<Form>
						<div className={s.form}>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter Site"
										name="site"
										title="Site"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter location"
										name="location"
										title="Location"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
							</div>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter Unit No."
										name="unit_no"
										title="Unit No"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter Feedback Type"
										name="topic"
										title="Feedback Type"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
							</div>
							<div className={s.column3}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter User"
										name="user"
										title="User"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter Mobile"
										name="phone_number"
										title="Mobile"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter Email"
										name="email"
										title="Email"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
							</div>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Identifier"
										name="caseId"
										title="Case Id"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Creation Date / Time"
										name="created_date"
										title="Submitted Date / Time"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
							</div>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter Message"
										name="message"
										title="Description" // Here Description or message is same only name is different to display in UI I will change it to description
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<TextArea
										type="text"
										placeholder="Enter Remark"
										name="remark"
										title="Remarks"
										typeEdit={isEdit}
									/>
								</div>
							</div>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Last Updation Date / Time"
										name="updated_date"
										title="Last Updated Date / Time"
										typeEdit={isEdit}
										disableEdit={true}
									/>
								</div>
								<div className={s.InputBox}>
									<div className={s.title}>Status *</div>
									<AsyncSelect
										noOptionsMessage={() => 'No data found'}
										loadOptions={toolsOptions}
										loadingMessage={() => 'loading facility ...'}
										placeholder="Choose Status"
										name="status"
										typeEdit={isEdit}
										title={status?.label}
										style={{ margin: '40%' }}
										value={values?.status}
									/>
								</div>
							</div>
							<InputFileReadOnly
								placeholder="Drag & Drop your jpg or png files here"
								name="photo"
								title="Feedback Photos"
								typeEdit={isEdit}
								initialValues={initValues?.photo}
								setValue={formikProps?.setFieldValue}
							/>
						</div>
						<div className={s.ButtonBox}>
							{isEdit ? (
								<div className={s.groupButton}>
									<Button
										text="Save Changes"
										type="submit"
										class_type={isSaveChangeParams ? "main" : "disabled"}
									/>
									{ /*<Button
										left_icon={<DeleteIcon />}
										text="Delete Feedback"
										onClick={() => onDelete()}
										class_type="red"
									/>*/ }
								</div>
							) : '' }
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
