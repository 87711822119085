import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { deleteSite, getSite, updateSite, updateSiteT } from '../../../api';
import { AddSiteForm } from '../../../components/Forms/AddSite/AddSiteForm';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import { siteDataT } from '../AddSite/AddSite';
import s from './GetSite.module.scss';

export const GetSite = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const axios = axiosInstance(history);
  const { id }: { id: string } = useParams();
  // const [status, setStatus] = useState({});

  const [infoSite, setInfoSite] = useState<siteDataT>();
  // const []

  useEffect(() => {
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    getSite(axios)({ id })
      .then((res: any) => {
        /* if(res?.data?.status == true)
        {
          setStatus({
            label: "Enable",
            value: res?.data?.status,
          })
        }
        else{
          setStatus({
            label: "Disable",
            value: res?.data?.status,
          })
        } */
        // var s = res.status == true ? true : false
        let data = res?.data
        /*let s = {}
        if(res?.data.status == true)
        {
          s = {label: 'Enable', value : data?.status}
        }
        else{
          s= {label: 'Disable', value : data?.status}
        }*/
        var newT = {
          name: data?.name,
          code: data?.code,
          braintree_merchant_id: data?.braintree_merchant_id,
          database_id: data?.database_id,
          bank_code: data?.bank_code,
          // status: s
          email: data?.email
        } 
        setInfoSite(newT);
        
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        console.error('site not found');
      });
  }, []);

  const deleteSites = (values: any) => {
    dispatch(
      changeShowModal({
        nameModal: 'deleteSite',
        stateModal: true,
        otherParams: { values, deleteFunc: deleteSite(axios) },
      }),
    );
  };
  const updateSites = (values: updateSiteT) => {
    // values.status = values.status.value;
    /* const s: any = values['status'];
    values.status = s.value */
    dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
    updateSite(axios)(id, values)
      .then(async (res: any) => {
        popUp({
          props: 'The action was successful',
          type: 'success',
          isCustomMessage: true,
        });
        const siteData = await res.data;
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        dispatch(
          changeShowModal({
            nameModal: 'updateSite',
            stateModal: true,
            otherParams: siteData,
          }),
        );
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
        new Error('Error update Site ');
      });
  };

  return (
    <div>
      <div className={s.title}>{infoSite?.name}</div>
      <div className={s.boxForm}>
        <AddSiteForm
          type="edit"
          initValues={infoSite}
          // status={status}
          onDelete={deleteSites}
          onSubmit={updateSites}
        />
      </div>
    </div>
  );
};
