import moment from 'moment';
import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';

export const headerColumn: HeaderColumnT[] = [
	{ columnName: 'caseId', align: 'left' },
	{ columnName: 'user', align: 'left' },
	{ columnName: 'unit_no', align: 'left' },
	{ columnName: 'topic', align: 'left' },
	{ columnName: 'created_date', align: 'left' },
	{ columnName: 'updated_date', align: 'left' },
	{ columnName: 'remark', align: 'left' },
	{ columnName: 'Description', align: 'left' },
	{ columnName: 'site', align: 'left' },
	{ columnName: 'status', align: 'right' },
	{ columnName: 'location', align: 'left' },
];

// Here description is same as message

type feedbackHistoryT = {
	// id: number,
	caseId: string,
	user: {
		first_name: string;
	}
	topic: string;
	message: string;
	unit_no: string;
	site: {
		name: string
	};
	created_date: string
	updated_date: string,
	remark: string
	status: string;
	location: string
};
export const dataRows: DataRowsT[] = [
	{
		name: 'caseId',
		title: 'Case ID',
		getCellValue: (row: feedbackHistoryT) => row?.caseId
	},
	{
		name: 'site',
		title: 'Site',
		getCellValue: (row: feedbackHistoryT) => row?.site?.name
	},
	{
		name: 'location',
		title: 'Location',
		getCellValue: (row: feedbackHistoryT) => {
			return row?.location ? row?.location : '-'
		},
	},
	{
		name: 'unit_no',
		title: 'Unit No',
		getCellValue: (row: feedbackHistoryT) => {
			return row?.unit_no ? row?.unit_no : '-'
		},
	},
	{
		name: 'topic',
		title: 'Feedback Type',
		getCellValue: (row: feedbackHistoryT) => {
			return row?.topic
		},
	},
	{
		name: 'description',
		title: 'Description',
		getCellValue: (row: feedbackHistoryT) => {
			return row?.message
		},
	},
	{
		name: 'created_date',
		title: 'Submitted Date',
		getCellValue: (row: feedbackHistoryT) => {
			return row?.created_date ? moment(row?.created_date).format('YYYY-MM-DD') : '-'
		},
	},
	{
		name: 'user',
		title: 'User',
		getCellValue: (row: feedbackHistoryT) => row?.user?.first_name
	},
	{
		name: 'status',
		title: 'Status',
		getCellValue: (row: feedbackHistoryT) => {
			if (row?.status === 'open') {
				return 'Open'
			}
			else if (row?.status === 'ongoing') {
				return 'Ongoing'
			}
			else if (row?.status === 'close') {
				return 'Close'
			}
			row?.status
		}
	},
];

/* export const columnExtensions: any[] = [
	{ columnName: "units_list", sortingEnabled: false },
	{ columnName: "role", sortingEnabled: false },
]; */

export const dataSorting: DataSortingT[] = [
	{ columnName: 'caseId', direction: 'desc' }
];
