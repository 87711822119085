import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useHistory as useHistoryDom, useLocation } from "react-router-dom";
import { getHeaderSiteOption } from "../../api";
import {
	changeShowModal,
	HeaderGetSiteData,
} from "../../store/actions/SideMenu";
import { HeaderGetSiteDataT } from "../../store/interface";
import axiosInstance from "../../utils/axios";
import { headerData } from "../../utils/headerData/headerData";
import { getTruePathName, popUp } from "../../utils/helpersFunction";
import { Button } from "../FormComponents/Button/Button";
import BackBtn from "./BackBtn/BackBtn";
import ExportButton from "./ExportButton/ExportButton";
import s from "./Header.module.scss";
import { SearchInput } from "./SearchInput/SearchInput";
import { SiteSelectorDropDown } from "./SiteSelectorDropDown/SiteSelectorDropDown";

export const Header = ({ ...props }) => {
	const historyDom = useHistoryDom();
	const history = useHistory();
	const axios = axiosInstance(history);
	const location = useLocation();
	const pathName = getTruePathName(location.pathname);
	let currentPageData: any;
	const dispatch = useDispatch();

	headerData.find((pagesJson, index, array) => {
		const pathUrl = pathName;

		if (pathUrl == pagesJson.pathName) {
			return (currentPageData = pagesJson);
		} else {
			return (currentPageData = null);
		}
	});

	console.log('CURRENT',currentPageData)

	const clickButtonHandler = () => {
		historyDom.push(`${location.pathname}/add`);
	};

	useEffect(() => {
		dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
		const siteData: HeaderGetSiteDataT[] = [];
		getHeaderSiteOption(axios)({})
			.then(async (res: any) => {
				const userData = await res.data;
				userData.map((item: { name: any; id: any }) => {
					siteData.push({
						label: item.name,
						value: item.id,
					});
				});
				dispatch(HeaderGetSiteData(siteData));

				dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
			})
			.catch((...e) => {
				popUp({ props: e });
				dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
				new Error("Error save Facilities ");
			});
	}, [])

	return (
		<>
			{currentPageData && (
				<div className={s.header}>
					{currentPageData.text && (
						<div className={s.text}>{currentPageData.text}</div>
					)}
					{currentPageData.backBtn && (
						<BackBtn
							pathName={currentPageData.backBtn.pathName}
							textContent={currentPageData.backBtn.textContent}
						/>
					)}
					{currentPageData.select ? (
						<div className={`${s.header_item} ${s.site_selector_dropdown}`}>
							<SiteSelectorDropDown />
						</div>
					) : (
						<div />
					)}
					<div className={`${s.header_item} ${s.search_button}`}>
						{currentPageData.search && (
							<div className={s.search_input}>
								<SearchInput placeholder={currentPageData.search} />
							</div>
						)}
						{currentPageData.exportButton && (
							<ExportButton
								pathName={currentPageData.exportButton.pathName}
								textContent={currentPageData.exportButton.textContent}
							/>
						)}
						{currentPageData.button && (
							<Button
								onClick={clickButtonHandler}
								class_type="main"
								wide="true"
								text={currentPageData.button}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Header;
