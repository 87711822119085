import { Form, Formik } from "formik";
import { equals } from "ramda";
import React, { useState } from "react";
import * as Yup from "yup";
import { Button } from "../../FormComponents/Button/Button";
import { Input } from "../../FormComponents/Input/Input";
import s from "../style/formStyle.module.scss";
// import { AsyncSelect } from '../../FormComponents/AsyncSelect/AsyncSelect';
// import { DeleteIcon } from "../../../assets/DeleteIcon";
// import { toolsOptions } from "./dataSiteForm";
// import { InputNotEdit } from "../../FormComponents/InputNotEdit/InputNotEdit";
// import { Label } from "@material-ui/icons";

type AddSiteFromT = {
	initValues: any;
	// defaultValue?: saveFacilitiesT
	onSubmit: any;
	onDeactivate?: any;
	onUpdate?: any;
	onReset?: any;
	type?: string;
	infoMember?: any;
	[key: string]: any;
};

const validationSchema = Yup.object({
	name: Yup.string().required("Site Name is a required field"),
	code: Yup.string().required("Site Address is a required field"),
	database_id: Yup.string().required("Database ID is a required field"),
	braintree_merchant_id: Yup.string().required(
		"Merchant ID is a required field"
	),
	bank_code: Yup.string().required("Bank Code ID is a required field"),
	email: Yup.string().required('Email is a required field').nullable(),
});

export const AddSiteForm = (props: AddSiteFromT) => {

	const [inputValue, onInputChange] = useState();
	const {
		initValues,
		onSubmit,
		// onDelete,
		type,
		infoMember
		// status
	} = props;

	const [isSaveChangeParams, setIsSaveChangeParams] = useState(false);
	const isEdit = type === "edit";
	return (
		<Formik
			enableReinitialize
			initialValues={initValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			infoMember={infoMember}
		>
			{({ values, ..._ }) => {
				if (isEdit) {
					const defaultTest = {
						...initValues,
					};
					const similarObjects: boolean = equals(values, defaultTest);
					if (!similarObjects && !isSaveChangeParams)
						setIsSaveChangeParams(true);
					if (similarObjects && isSaveChangeParams)
						setIsSaveChangeParams(false);
				}
				return (
					<Form>
						<div className={s.form}>
							<div className={s.InputBox}>
								<Input
									type="text"
									placeholder="This site name will be displayed to users in the mobile app"
									name="name"
									title="Site Name *"
									typeEdit={isEdit}
								/>
							</div>
							<div className={s.InputBox}>
								<Input
									type="text"
									placeholder="Enter the address where site is located"
									name="code"
									title="Site Address *"
									typeEdit={isEdit}
								/>
							</div>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Merchant ID from Payment Gateway provider"
										name="braintree_merchant_id"
										title="Merchant ID *"
										typeEdit={isEdit}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Subsidiary from Finance System"
										name="database_id"
										title="Subsidiary *"
										typeEdit={isEdit}
									/>
								</div>
							</div>
							<div className={`${s.lineParams}`}>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter the Bank Account ID"
										name="bank_code"
										title="Bank Account Id *"
										typeEdit={isEdit}
									/>
								</div>
								<div className={s.InputBox}>
									<Input
										type="text"
										placeholder="Enter email"
										name="email"
										title="Email *"
										typeEdit={isEdit}
									/>
								</div>
							</div>
						</div>
						<div className={s.ButtonBox}>
							{isEdit ? (
								<div className={s.groupButton}>
									<Button
										text="Save Changes"
										type="submit"
										class_type={isSaveChangeParams ? "main" : "disabled"}
									/>
								</div>
							) : (
								<Button text="Publish Site" type="submit" class_type="main" />
							)}
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
