import { Advertising } from "../../assets/Advertising";
import { Booking } from "../../assets/Booking";
import { Building } from "../../assets/Building";
import { Envelope } from "../../assets/Envelope";
import { Help } from "../../assets/Help";
import { Member } from "../../assets/Member";
import { Promotion } from "../../assets/Promotion";
import { Tools } from "../../assets/Tools";
import { memberDataT } from "../../scenes/Members";
import { TYPE_REDUCER } from "../../utils/constants";
import { PrivateRoutes } from "../../utils/constants/routes";
import {
  HeaderInfoT,
  MenuT,
  SettingsT,
  TablesInfoT,
  TablesT,
} from "../interface";

export type NameModals =
    "deleteFeedback"
  | "addPromotion"
  | "deletePromotion"
  | "bookingSuccessful"
  | "cancelBooking"
  | "addNewFacility"
  | "deleteFacility"
  | "sentNotification"
  | "newSite"
  | "deleteSite"
  | "updateSite"
  | "updateFeedback"
  | "addMembers"  
  | "memberResetPassword"
  | "newAccessCodeMembers"
  | "deleteRentalDetails"
  | "loader"
  | "addNotification"
  | "addHelp"
  | "modalAddFacilityBook"
  | "facilityDeleteBooking"
  | "deleteAdvertising"
  | "newAdvertising"
  | "newTools"
  | "deleteFaq"
  | "deleteDoorInput"
  | "facilityBookingInfo"
  | "syncCreditMemo";
export type NameTables =
  | "FacilitiesPromotion"
  | "Members"
  | "Invoices"
  | "PaymentHistoryData"
  | "Sites"
  | "Notifications"
  | "Help"
  | "Facilities"
  | "FacilitiesBooking"
  | "ads"
  | "Feedbacks";
  // | "Credit_Memo";

export type ModalStatusT = {
  isShow: boolean;
  otherParams?: any;
};
export type ModalsListT = Record<NameModals, ModalStatusT>;
export type AllTablesT = Record<NameTables, TablesT>;

export type TableListT = {
  tablesInfo: TablesInfoT;
  tables: AllTablesT;
};
export type SideMenuT = {
  sideMenu: MenuT[];
  settings: SettingsT;
  modalsList: ModalsListT;
  tablesList: TableListT;
  memberInfo?: memberDataT;
  headerInfo: HeaderInfoT;
};

const initialState: SideMenuT = {
  settings: {
    sideMenuMainSelected: "",
    sideMenuChildSelected: null,
  },
  sideMenu: [
    {
      id: "members",
      link: PrivateRoutes.members.list,
      menuData: {
        itemIcon: Member,
        label: "Users",
      },
    },
    {
      id: "invoices",
      link: PrivateRoutes.invoices.list,
      menuData: {
        itemIcon: Promotion,
        label: "Invoices",
      },
    },
    {
      id: "promotions",
      link: PrivateRoutes.promotions.list,
      menuData: {
        itemIcon: Promotion,
        label: "Facilities Promotion",
      },
    },
    {
      id: "facilities&booking",
      link: PrivateRoutes.facilities.list,
      menuData: {
        itemIcon: Booking,
        label: "Facilities & Booking",
        subMenu: [
          {
            id: "facilities",
            link: PrivateRoutes.facilities.list,
            menuData: {
              label: "Facilities list",
            },
          },
          {
            id: "booking",
            link: PrivateRoutes.booking.list,
            menuData: {
              label: "Booking list",
            },
          },
        ],
        defaultValue: "facilities",
      },
    },
    {
      id: "help",
      link: PrivateRoutes.help.list,
      menuData: {
        itemIcon: Help,
        label: "Help",
      },
    },
    {
      id: "notifications",
      link: PrivateRoutes.notifications.list,
      menuData: {
        itemIcon: Envelope,
        label: "Notifications",
      },
    },
    {
      id: "sites",
      link: PrivateRoutes.sites.list,
      menuData: {
        itemIcon: Building,
        label: "Sites",
      },
    },
    {
      id: "Advertising",
      link: PrivateRoutes.advertising.list,
      menuData: {
        itemIcon: Advertising,
        label: "Advertising",
      },
    },
    {
      id: "feedbacks",
      link: PrivateRoutes.feedbacks.list,
      menuData: {
        itemIcon: Promotion,
        label: "Feedbacks",
      },
    },
    {
      id: "tools",
      link: PrivateRoutes.tools.add,
      menuData: {
        itemIcon: Tools,
        label: "Tools",
        subMenu: [
          {
            id: "invoices",
            link: PrivateRoutes.tools.add,
            menuData: {
              label: "Invoices",
            },
          },
          {
            id: "doorPin",
            link: PrivateRoutes.tools.door,
            menuData: {
              label: "Door Passcode",
            },
          },
        ],
        defaultValue: "invoices",
      },
    },
  ],
  modalsList: {
    addPromotion: {
      isShow: false,
    },
    deletePromotion: {
      isShow: false,
    },
    bookingSuccessful: {
      isShow: false,
    },
    cancelBooking: {
      isShow: false,
    },
    addNewFacility: {
      isShow: false,
    },
    deleteFacility: {
      isShow: false,
    },
    sentNotification: {
      isShow: false,
    },
    newSite: {
      isShow: false,
    },
    deleteSite: {
      isShow: false,
    },
    updateSite: {
      isShow: false,
    },
    updateFeedback: {
      isShow: false,
    },
    addMembers: {
      isShow: false,
    },
    loader: {
      isShow: false,
    },
    addHelp: {
      isShow: false,
    },
    addNotification: {
      isShow: false,
    },
    newAccessCodeMembers: {
      isShow: false,
    },
    memberResetPassword: {
      isShow: false,
    },
    deleteRentalDetails: {
      isShow: false,
    },
    modalAddFacilityBook: {
      isShow: false,
    },
    facilityDeleteBooking: {
      isShow: false,
    },
    newTools: {
      isShow: false,
    },
    deleteAdvertising: {
      isShow: false,
    },
    newAdvertising: {
      isShow: false,
    },
    deleteFaq: {
      isShow: false,
    },
    deleteDoorInput: {
      isShow: false,
    },
    facilityBookingInfo: {
      isShow: false,
    },
    syncCreditMemo : {
      isShow: false,
    },
    deleteFeedback: {
      isShow: false,
    },
  },
  memberInfo: undefined,
  headerInfo: {
    selected: {
      label: "All sites",
      value: "all",
    },
    siteData: [],
    search: "",
  },
  tablesList: {
    tablesInfo: {
      take: 0,
      virtualPageSize: 10,
      maxRows: 50000,
      currentPage: 0,
      currentIndexRow: 0,
      backDetailBtn: false,
    },
    tables: {
      FacilitiesPromotion: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Members: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Invoices: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      PaymentHistoryData: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Sites: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Notifications: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Help: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Facilities: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      FacilitiesBooking: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      ads: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
      Feedbacks: {
        rows: [],
        skip: 0,
        requestedSkip: 0,
        page: 1,
        totalCount: 0,
        loading: false,
        lastQueryString: "",
      },
    },
  },
};

const SideMenu = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPE_REDUCER.EXAMPLE_ACTION: {
      return {
        ...state,
      };
    }
    case TYPE_REDUCER.SELECT_SIDE_MENU: {
      let { mainSelected, childSelected } = action.payload;
      if (childSelected === null) {
        const selectedMainObject = state.sideMenu.filter(
          (obj) => obj.id === mainSelected
        )[0];
        childSelected = selectedMainObject?.menuData?.defaultValue;
      }
      return {
        ...state,
        settings: {
          sideMenuMainSelected: mainSelected,
          sideMenuChildSelected: childSelected,
        },
      };
    }
    case TYPE_REDUCER.CHANGE_SHOW_MODALS: {
      const { nameModal, stateModal, otherParams } = action.payload;
      const paramsModals: ModalStatusT = {
        isShow: stateModal,
        otherParams: otherParams,
      };

      return {
        ...state,
        modalsList: {
          ...state.modalsList,
          [nameModal]: paramsModals,
        },
      };
    }
    case TYPE_REDUCER.CURRENT_DATA_INFO: {
      return {
        ...state,
        tablesList: {
          ...state.tablesList,
          tablesInfo: {
            ...state.tablesList.tablesInfo,
            ...action.payload,
          },
        },
      };
    }
    case TYPE_REDUCER.UPDATE_ROWS: {
      const {
        nameTable,
        otherParams,
      }: { nameTable: NameTables; otherParams: any } = action.payload;
      return {
        ...state,
        tablesList: {
          ...state.tablesList,
          tables: {
            ...state.tablesList.tables,
            [nameTable]: {
              ...state.tablesList.tables[nameTable],
              // rows: [...state.tablesList.tables[nameTable].rows, ...otherParams.rows],
              rows: otherParams.rows,
              skip: otherParams.skip,
              totalCount: otherParams.totalCount,
            },
          },
        },
      };
    }

    case TYPE_REDUCER.UPDATE_QUERY: {
      const { tableName, query }: { tableName: NameTables; query: string } =
        action.payload;
      return {
        ...state,
        tablesList: {
          ...state.tablesList,
          tables: {
            ...state.tablesList.tables,
            [tableName]: {
              ...state.tablesList.tables[tableName],
              lastQueryString: query,
            },
          },
        },
      };
    }

    case TYPE_REDUCER.START_LOADING: {
      const {
        requestedSkip,
        page,
        tableName,
      }: { requestedSkip: any; page: any; tableName: NameTables } =
        action.payload;

      return {
        ...state,
        tablesList: {
          ...state.tablesList,
          tables: {
            ...state.tablesList.tables,
            [tableName]: {
              ...state.tablesList.tables[tableName],
              requestedSkip,
            },
          },
        },
      };
    }

    case TYPE_REDUCER.FETCH_INIT:
      return {
        ...state,
        loading: true,
      };
    case TYPE_REDUCER.UPDATE_PREV_QUERY: {
      const { nameTable, query }: { nameTable: NameTables; query: string } =
        action.payload;
      return {
        ...state,
        tablesList: {
          ...state.tablesList,
          tables: {
            ...state.tablesList.tables,
            [nameTable]: {
              ...state.tablesList.tables[nameTable],
              prevQuery: query,
            },
          },
        },
      };
    }
    case TYPE_REDUCER.UPDATE_NEXT_QUERY: {
      const { nameTable, query }: { nameTable: NameTables; query: string } =
        action.payload;
      return {
        ...state,
        tablesList: {
          ...state.tablesList,
          tables: {
            ...state.tablesList.tables,
            [nameTable]: {
              ...state.tablesList.tables[nameTable],
              nextQuery: query,
            },
          },
        },
      };
    }

    case TYPE_REDUCER.HEADER_UPDATE_SELECT:
      const { selected } = action.payload;
      return {
        ...state,
        headerInfo: {
          ...state.headerInfo,
          selected,
        },
      };
    case TYPE_REDUCER.HEADER_UPDATE_SEARCH:
      const { search } = action.payload;
      return {
        ...state,
        headerInfo: {
          ...state.headerInfo,
          search,
        },
      };
    case TYPE_REDUCER.HEADER_GET_SITE_DATA:
      const siteData = action.payload;
      return {
        ...state,
        headerInfo: {
          ...state.headerInfo,
          siteData,
        },
      };
    case TYPE_REDUCER.MEMBERS_INFO:
      return {
        ...state,
        memberInfo: action.payload,
      };

    default:
      return state;
  }
};

export default SideMenu;
