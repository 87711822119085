type toolsT = {
    value: string,
    label: string,
}
type toolsOptionsT = {
    options: toolsT[],
    [key: string]: any
}
type toolsDataT = {
    enable: boolean;
    disable: boolean;
    [key: string]: any
}
export const toolsData: toolsDataT = {
    enable: true,
    disable: false,
}
export const toolsOptions = (): toolsOptionsT => ({
    options: [
        {
            value: 'open',
            label: 'Open',
        },
        {
            value: 'ongoing',
            label: 'Ongoing',
        },
        {
            value: 'close',
            label: 'Close',
        },
    ],
});