import { AxiosInstance } from "axios";

export type getFeedbackT = {
  id: string,
}

export type saveFeedbackT = {
  user: {
    first_name: string;
  }
  topic?: string,
  message?: string,
  site?: boolean,
  status: any
}

export type deleteFeedbackT = {
  id: string
}
export const deleteFeedback = (axios: AxiosInstance) => ({ ...props }: deleteFeedbackT) => {
  const { id } = props;
  return axios.delete(`/feedback/${id}/`);
}



export const addFeedback = (axios: AxiosInstance) => (props: saveFeedbackT) => {
  return axios.post(`/feedback/`, { ...props });
}

export const getFeedback = (axios: AxiosInstance) => () => {
  return axios.get('/feedback/list')
}

export const getFeedbackById = (axios: AxiosInstance) => ({ id }: getFeedbackT) => {
  return axios.get(`/feedback/${id}/`)
}

export type updateFeedBackT = {
  // user: {
  //   first_name: string;
  // }
  // topic?: string,
  // message?: string,
  // site?: boolean,
  status: any
  remark:any
}

export const updateFeedback = (axios: AxiosInstance) => (id: string, body: updateFeedBackT) => {
  return axios.patch(`/feedback/${id}/`, body)
}

export type exportFeedbackT = {
  search: string;
  responseType:string
}

export const exportFeedback = (axios: AxiosInstance) => (body: exportFeedbackT) => {
  return axios.post(`/feedback/export/`,body)
}
