import { DataRowsT, DataSortingT, HeaderColumnT } from './tablesType';

export const headerColumn: HeaderColumnT[] = [
  { columnName: 'id', align: 'left' },
  { columnName: 'name', align: 'left' },
  { columnName: 'address', align: 'left' },
  { columnName: 'merchantId', align: 'right' },
  { columnName: 'databaseId', align: 'right' },
  { columnName: 'email', align: 'right' },
];

export const dataRows: DataRowsT[] = [
  {
    name: 'id',
    title: 'ID',
    getCellValue: (row: { id: string }): string => row.id,
  },
  {
    name: 'name',
    title: 'Site Name',
    getCellValue: (row: { name: string }): string => row.name,
  },
  {
    name: 'address',
    title: 'Site Adress',
    getCellValue: (row: { code: string }): string => row.code,
  },
  {
    name: 'merchantId',
    title: 'Merchant ID',
    getCellValue: (row: { braintree_merchant_id: string }): string => row.braintree_merchant_id,
  },
  {
    name: 'databaseId',
    title: 'Subsidiary',
    getCellValue: (row: { database_id: string }): string => row.database_id,
  },
  {
    name: 'email',
    title: 'Email',
    getCellValue: (row: { email: string }): string => row.email,
  }
];

export const dataSorting: DataSortingT[] = [
  { columnName: 'id', direction: 'asc' },
];
