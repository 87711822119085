import React from 'react';
// import { TableComponent } from '../../../components/TableComponent/TableComponent';
import {
  // columnExtensions,
  dataRows,
  dataSorting,
  headerColumn
} from '../../../utils/tablesData/FeedBacksData';
import { DefaultContentT } from '../../../utils/tablesData/tablesType';
import { TableComponencDynamicColorRows } from '../../../components/TableComponent/TableComponencDynamicColorRows';

export const ListFeedbacks = () => {
  const defaultContent: DefaultContentT = {
    title: 'FeedBacks',
    tableName: 'Feedbacks',
    tableAPI: '/feedback/list',
    dataRows: dataRows,
    dataSorting: dataSorting,
    // columnExtensions: columnExtensions,
    tableColumnExtensions: headerColumn,
  };

  return (
    <>
       <TableComponencDynamicColorRows data={defaultContent} />
    </>
  );
};
