import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { saveFacilitiesT } from '../../../api';
import { Button } from '../../FormComponents/Button/Button';
import { DataPicker } from '../../FormComponents/DataPicker/DataPicker';
import s from './AddToolsForm.module.scss';
// import { toolsOptions } from './dataToolsForm';

type AddToolsCreditMemoFormT = {
	initialValues: any;
	onSubmit: any;
	onDelete?: any;
	type?: string;
	[key: string]: any;
};

const getValidationSchema: any = () => {
	return Yup.object({
		sync_from: Yup.string().required('The field is required').nullable(),
		sync_to: Yup.string().required('The field is required').nullable(),
	});
};

export const AddToolsCreditMemoForm = ({ ...props }: AddToolsCreditMemoFormT) => {
	const { initialValues, onSubmit } = props;
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			validationSchema={getValidationSchema()}
			onSubmit={onSubmit}
		>
			{({ values }) => {

				var date = new Date();
				var maxDate = new Date(date.setMonth(date.getMonth() + 3));

				return (
					<Form>
						<div className={s.form}>
							<div className={s.lineParams}>
								<div className={s.InputBox}>
									<div className={s.title}>Period start *</div>
									<DataPicker
										placeholderText="Select sync start date"
										name="sync_from"
										dateFormat="yyyy-M-dd"
										selected={startDate}
										onChangeCustom={setStartDate}
										startDate={startDate}
										endDate={endDate}
										maxDate={new Date()}
										value={initialValues.sync_from}
									/>
								</div>

								<div className={s.InputBox}>
									<div className={s.title}>Period end *</div>
									<DataPicker
										placeholderText="Select sync End date"
										name="sync_to"
										dateFormat="yyyy-M-dd"
										onChangeCustom={setEndDate}
										selected={endDate}
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										maxDate={maxDate}
										value={initialValues.sync_to}
									/>
								</div>
							</div>

							<div className={s.button}>
								<Button text="Submit" type="submit" class_type="main" />
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
