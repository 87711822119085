import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import Image from '../../../assets/Image';
import { Trashcan } from '../../../assets/Trashcan';
import s from './InputFile.module.scss';

const addPhoto = ({ event, setValue, name, setActiveFile, setSrcImage }: any) => {
	const files = event.target.files;
	const myFiles = Array.from(files);
	setValue(name, myFiles);
	setActiveFile(true);
	// FileReader support

	if (FileReader && files && files.length) {
		const fr = new FileReader();
		fr.onload = function () {
			const image = fr.result;
			setSrcImage(image);
		};
		fr.readAsDataURL(files[0]);
	} // Not supported
	else {
		// fallback -- perhaps submit the input to an iframe and temporarily store
		// them on the server until the user's session ends.
	}
};

const deletePhoto = ({ setValue, setActiveFile }: any) => {
	setValue('photo', []);
	setActiveFile(false);
};

type InputFileT = {
	hint?: string;
	title?: string;
	name: string;
	typeEdit: boolean;
	placeholder: string;
	setValue: any;
	typeFile?: 'image' | 'file';
	initialValues: any;
};
export const InputFileReadOnly = ({ ...props }: InputFileT) => {
	const {
		hint,
		title,
		typeEdit,
		placeholder,
		setValue,
		typeFile,
		initialValues,
		name,
	} = props;
	const [field, meta]: any = useField(name);
	let containerClass = `${s.input_photo_area}`;
	let error = '';
	let isError = !!error;
	if (meta.touched) {
		error = meta.error;
		isError = !!error;
	}

	if (isError) containerClass += ` ${s.error}`;

	const [activeFile, setActiveFile] = useState(false);
	const [srcImage, setSrcImage] = useState([]);

	useEffect(() => {
		if (typeEdit) {
			setSrcImage(initialValues);
		}

		field.value != '' && setActiveFile(true);
	}, [initialValues]);

	return (
		<div className={s.input__wrap}>
			<div className={s.input_photo_box}>
				{title && <div className={s.input_title}>{title}</div>}
				{(activeFile && srcImage?.length > 0) ? srcImage.map((val, index) => (
					<a href={val} target="_blank">
						<img
							key={index}
							style={{ marginRight: index !== srcImage.length - 1 ? '10px' : '0' }}
							className={s.image}
							src={val}
							alt={`image-${index}`}
						/>
					</a>
				)) : 'No Feedback Photos'}
			</div>
		</div>
	);
};
