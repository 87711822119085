import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { changeShowModal } from '../../../store/actions/SideMenu';
import axiosInstance from '../../../utils/axios';
import { popUp } from '../../../utils/helpersFunction';
import s from './GetFeedback.module.scss';
import { feedbackDataT } from '../AddFeedback/AddFeedback';
import { AddFeedbackForm } from '../../../components/Forms/AddFeedbackForm/AddFeedbackForm';
// import { deleteFeedback, getFeedbackById, updateFeedBackT, updateFeedback } from '../../../api/Feedback';
import { getFeedbackById, updateFeedBackT, updateFeedback } from '../../../api/Feedback';
import moment from 'moment';

export const GetFeedback = (props: any) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const axios = axiosInstance(history);
	const { id }: { id: string } = useParams();
	const [status, setStatus] = useState({});

	const [infoFeedback, setInfoFeedback] = useState<feedbackDataT>();
	// const []

	useEffect(() => {
		dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
		getFeedbackById(axios)({ id })
			.then((res: any) => {
				if (res?.data?.status === 'ongoing') {
					setStatus({
						label: "Ongoing",
						value: res?.data?.status,
					})
				}
				if (res?.data?.status === 'close') {
					setStatus({
						label: "Close",
						value: res?.data?.status,
					})
				}
				if (res?.data?.status === 'open') {
					setStatus({
						label: "Open",
						value: res?.data?.status,
					})
				}
				// var s = res.status == true ? true : false
				let data = res?.data
				let s = {}
				if (res?.data.status === 'ongoing') {
					s = { label: 'Ongoing', value: data?.status }
				}
				if (res?.data.status === 'close') {
					s = { label: 'Close', value: data?.status }
				}
				if (res?.data.status === 'open') {
					s = { label: 'Open', value: data?.status }
				}
				const extractImage = data?.feedback_images?.map((val: any) => val?.image)
				var newT = {
					id: data?.id,
					caseId: data?.caseId,
					user: data?.user?.first_name,
					email: data?.user?.email,
					phone_number: data?.user?.phone_number,
					topic: data?.topic,
					message: data?.message,
					unit_no: data?.unit_no,
					site: data?.site?.name,
					status: s,
					location: data?.location && data?.location,
					created_date: data?.created_date && moment(data?.created_date).format('YYYY-MM-DD hh:mm:ss a'),
					updated_date: data?.updated_date && moment(data?.updated_date).format('YYYY-MM-DD hh:mm:ss a'),
					remark: data?.remark,
					photo: extractImage ? extractImage : []
				}
				setInfoFeedback(newT);

				dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
			})
			.catch((...e: any) => {
				popUp({ props: e });
				dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
				console.error('site not found');
			});
	}, []);

	/* const onDeleteFeedback = (id: any) => {
		dispatch(
			changeShowModal({
				nameModal: 'deleteFeedback',
				stateModal: true,
				otherParams: { id, deleteFunc: deleteFeedback(axios) },
			}),
		);
	}; */

	const updateFeedbackStatus = (values: updateFeedBackT) => {
		const s: any = values['status'];
		values.status = s.value
		dispatch(changeShowModal({ nameModal: 'loader', stateModal: true }));
		updateFeedback(axios)(id, { status: values.status, remark: values.remark })
			.then(async (res: any) => {
				popUp({
					props: 'The action was successful',
					type: 'success',
					isCustomMessage: true,
				});
				const updateFeedbackData = await res.data;
				dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
				dispatch(
					changeShowModal({
						nameModal: 'updateFeedback',
						stateModal: true,
						otherParams: updateFeedbackData,
					}),
				);
			})
			.catch((...e: any) => {
				popUp({ props: e });
				dispatch(changeShowModal({ nameModal: 'loader', stateModal: false }));
				new Error('Error update Site ');
			});
	};

	return (
		<div>
			<div className={s.title}>{infoFeedback?.message}</div>
			<div className={s.boxForm}>
				<AddFeedbackForm
					type="edit"
					initValues={infoFeedback}
					status={status}
					// onDelete={(props: string) => onDeleteFeedback(id)}
					onSubmit={updateFeedbackStatus}
				/>
			</div>
		</div>
	);
};
