import React from 'react';
import { ButtonT } from '../../../utils/headerData/headerTypes';
import s from './ExportButton.module.scss';
import axiosInstance from "../../../utils/axios";
import { useHistory } from 'react-router-dom';
import { setCurrentTableInfo } from '../../../store/actions/SideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { TablesInfoT } from '../../../store/interface';
import { getTableInfo } from '../../../store/selectors/selectors';
import { Button } from "../../FormComponents/Button/Button";
import { exportFeedback } from '../../../api/Feedback';
import * as XLSX from 'xlsx';

export default function ExportButton(props: ButtonT) {

	const { pathName, textContent } = props;

    const handlerClick = async () => {
        try {
            const axios = axiosInstance();
            const search = localStorage.getItem('search');

            const response = await axios.post('feedback/export/', { search: search });

            const worksheet = XLSX.utils.json_to_sheet(response.data);

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Feedback Data');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Feedback_data.xlsx';
            link.click();
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };
	
	return (
		<div>
			<Button onClick={handlerClick}
				text={textContent}
				type="submit"
				class_type={s.btn}
			/>
		</div>
	);
}
