/* eslint-disable no-prototype-builtins */
import {
	CustomPaging,
	PagingState,
	SortingState,
	Table,
} from "@devexpress/dx-react-grid";
import {
	Grid,
	PagingPanel,
	TableHeaderRow,
	VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useHistory as useHistoryDom, useLocation } from "react-router-dom";
import { getTableData } from "../../api";
import { DeleteIcon } from "../../assets/DeleteIcon";
import { Eye } from "../../assets/Eye";
import {
	changeShowModal,
	setCurrentTableInfo,
	StartLoading,
	UpdateRows,
} from "../../store/actions/SideMenu";
import { TablesInfoT } from "../../store/interface";
import {
	getHeaderInfo,
	getStateTable,
	getTableInfo,
} from "../../store/selectors/selectors";
import axiosInstance from "../../utils/axios";
import { getTruePathName, popUp } from "../../utils/helpersFunction";
import s from "./TableComponent.module.scss";

type sortingT = {
	columnName: string;
	direction: string;
};

type getQueryStringT = {
	localSorting?: boolean;
	localPagination?: number;
};
export const TableComponencDynamicColorRows = ({ data }: any) => {
	const {
		tableName,
		tableAPI,
		title,
		dataRows,
		dataSorting,
		tableColumnExtensions,
		customizeCell,
		customizeCoLumn,
		filterBySiteName = "rental__unit__site__name",
		columnExtensions = [],
		customParams = null,
		customStyle = null,
		rowClickable = true,
		sortingBySite = true,
		sortingParams = true,
		openInfoModalIfClickOnRow = false,
		redirectOnDetailPage = false,
		setButton,
	} = data;
	const { rows, skip, requestedSkip, lastQueryString, loading } = useSelector(

		getStateTable(tableName)
	);

	// console.log("DATAROWS", dataRows)

	const wrapper: any = useRef(null);
	const { selected, search } = useSelector(getHeaderInfo);
	const [isUseSorLoading, setIsUseSorLoading] = useState(false);
	const [startLoadingPage, setStartLoadingPage] = useState(true);
	const [countOfActiveUsers, setCountOfActiveUsers] = useState(-1);
	const [sorting, setSorting]: any = useState(dataSorting);
	const [sortingStateColumnExtensions] = useState(columnExtensions);
	const [oldSortParams, setOldSortParams]: any = useState();
	let [isFirstLoad, setIsFirstLoad]: any = useState({
		firstLoadSelect: false,
		localPaginationLoad: false,
	});

	const funcChangeFirstLoad = ({
		localPaginationLoad = isFirstLoad["localPaginationLoad"],
		firstLoadSelect = isFirstLoad["firstLoadSelect"],
	}: any) => {
		if (localPaginationLoad) {
			setCurrentPage(0);
		}
		setIsFirstLoad({
			firstLoadSelect,
			localPaginationLoad,
		});
	};

	const [totalRowCount, setTotalRowCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const pageSize = 50;

	const tableInfo: TablesInfoT = useSelector(getTableInfo);
	const tableHeight = `${window.innerHeight - 100 - 114}px`;
	const history = useHistory();
	const historyDom = useHistoryDom();
	const location = useLocation();
	const pathName = getTruePathName(location.pathname);
	const pageName = pathName.split("/")[1];
	const dispatch = useDispatch();
	const axios = axiosInstance(history);

	const getQueryString = ({
		localSorting = undefined,
		localPagination = undefined,
	}: getQueryStringT) => {
		// localPagination && funcChangeFirstLoad({
		//   localPaginationLoad: true
		// })
		let queryString = `page=${localPagination ? localPagination : currentPage + 1
			}`;
		let sortParams = "&ordering=";
		if (localSorting) {
			sortParams += oldSortParams;
		} else {
			if (sorting.length && sortingParams) {
				sorting.map(({ columnName, direction }: sortingT) => {
					sortParams += `${direction === "desc" ? "-" : ""}${columnName},`;
				});
				setOldSortParams(sortParams);
			}
		}

		if (selected.label.length && sortingBySite && selected.value != "all") {
			queryString += `&${filterBySiteName}=${selected.label}`;
		}

		if (customParams != null) {
			queryString += customParams;
		}

		if (search.length) {
			queryString += `&search=${search}`;
		}
		queryString += sortParams;
		return queryString;
	};
	const updateData = (params: string) => {
		dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
		getTableData(axios)({ params: params, tableAPI: tableAPI })

			.then(async (res: any) => {
				setTotalRowCount(res.data.count);
				dispatch(
					UpdateRows({
						nameTable: tableName,
						otherParams: {
							rows: res.data.results,
						},
					})
				);
				dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
				funcChangeFirstLoad({
					localPaginationLoad: false,
					firstLoadSelect: true,
				});
			})
			.catch((...e) => {
				popUp({ props: e });
				dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
				new Error("Error save Facilities ");
			});
	};
	const showActiveMembers = () => {
		let params = "no_page";
		if (selected.label.length && sortingBySite && selected.value != "all") {
			params += `&${filterBySiteName}=${selected.label}`;
		}
		dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
		getTableData(axios)({ params: params, tableAPI: tableAPI })
			.then(async (res: any) => {
				let count = 0;
				const members = res.data.results ? res.data.results : res.data;
				members.map((item: any) => {
					const status = item.is_active && item.activated;
					if (status) {
						count++;
					}
				});

				setCountOfActiveUsers(count);
				dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
			})
			.catch((...e) => {
				console.log("3123131e: ", e);
				popUp({ props: e });
				dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
				new Error("Error save Facilities ");
			});
	};
	const loadData = () => {
		const queryString = getQueryString({});
		if (queryString !== lastQueryString && !loading) {
			updateData(queryString);
			setIsUseSorLoading(true);
		}
	};

	const loadSortData = () => {
		const queryString = getQueryString({});
		if (isUseSorLoading === true) {
			updateData(queryString);
		}
	};

	const loadSelectData = () => {
		if (selected.label.length) {
			const queryString = getQueryString({ localPagination: 1 });
			funcChangeFirstLoad({
				localPaginationLoad: true,
			});
			updateData(queryString);
		}
	};

	const loadSearchData = () => {
		if (isFirstLoad.firstLoadSelect || !startLoadingPage) {
			const queryString = getQueryString({ localPagination: 1 });
			funcChangeFirstLoad({
				localPaginationLoad: true,
			});
			updateData(queryString);
		}
	};

	useEffect(() => {
		loadData();
		setStartLoadingPage(false);
		// tableInfo.currentIndexRow
		if (tableInfo.backDetailBtn) {
			setCurrentPage(tableInfo.currentPage);
		}
	}, [tableName]);

	useEffect(() => {
		if (isFirstLoad.localPaginationLoad) {
			funcChangeFirstLoad({
				localPaginationLoad: false,
			});
		} else if (!isFirstLoad.localPaginationLoad && !startLoadingPage) {
			loadData();
			wrapper.current.scrollTo({
				top: 0,
			});
		}
		if (currentPage === tableInfo.currentPage) {
			if (wrapper.current !== null) {
				wrapper.current.scrollTo({
					top: 52 * tableInfo.currentIndexRow,
				});
			}
			dispatch(
				setCurrentTableInfo({
					currentIndexRow: 0,
					currentPage: 0,
					backDetailBtn: false,
				})
			);
		}
	}, [currentPage]);

	useEffect(() => {
		loadSortData();
	}, [sorting]);

	useEffect(() => {
		if (
			isFirstLoad.firstLoadSelect &&
			isUseSorLoading &&
			selected.value != ""
		) {
			loadSelectData();
			if (countOfActiveUsers >= 0) {
				showActiveMembers();
			}
		}
	}, [selected]);

	useEffect(() => {
		loadSearchData();
	}, [search]);

	const customRowComponent = (
		props: React.PropsWithChildren<Table.DataRowProps>
	) => {
		const { row } = props;

		return (
			<VirtualTable.Row
				{...props}
				key={row.id}
				style={{
					cursor: rowClickable ? 'pointer' : 'default',
				}}
				onClick={(e: any) =>
					virtualRowHandler({
						e,
						id: row.id,
						rowIndex: props.tableRow.rowId,
					})
				}
				className={`${s.table_row_item} ${!rowClickable ? s.default : null}`}
			/>
		);
	};

	function virtualRowHandler({ e, id, rowIndex }: any) {
		clickRowHuddler({ id, rowIndex });
	}

	type customCellComponentT = {
		title?: string;
		[key: string]: any;
	};

	const customCellComponent = (
		props: React.PropsWithChildren<Table.DataCellProps> & customCellComponentT
	) => {
		let CustomCell;
		let styleContainer;
		if (customizeCoLumn) {
			const checkCustomColumnValue = customizeCoLumn.hasOwnProperty(
				props.column.name
			);
			styleContainer = checkCustomColumnValue
				? customizeCoLumn[props.column.name]
				: undefined;
		}
		if (customizeCell) {
			const checkCustomCellValue = customizeCell.hasOwnProperty(props.value);
			styleContainer = checkCustomCellValue
			? customizeCell[props.value]
			: styleContainer;
		}
		let cellStyle = {};
		if(props.column.name == 'status') {
			// Define a dynamic style based on the status of the row
			switch (props.value) {
				case 'Open':
					cellStyle = { color: '#f74040' };
					break;
				case 'Ongoing':
					cellStyle = { color: '#4468ab' };
					break;
				case 'Close':
					cellStyle = { color: '#04a204' };
					break;
				default:
					cellStyle = {};
			}
		}
			
		CustomCell = (
			<VirtualTable.Cell
				title={typeof props.value === "string" ? props.value : props.title}
				style={cellStyle}
				{...props}
			/>
		);

		if (setButton) {
			if (setButton.column == props.column.name) {
				const isHiddenCustomBtn = setButton.hiddenEvent
					? setButton.hiddenEvent(props.row)
					: false;
				CustomCell = (
					<>
						{CustomCell}
						<div
							onClick={(e) =>
								setButton.clickEvent({
									e,
									props,
									refresh: () =>
										updateData(getQueryString({ localSorting: true })),
									// refresh: () => updateData(`page=${1}&show_all=`),
								})
							}
							className={`${setButton.style} ${isHiddenCustomBtn ? s.hidden : null
								}`}
						>
							{setButton.svg && <DeleteIcon fill="#7A7A96" />}
							{setButton.text}
						</div>
					</>
				);
			}
		}

		return CustomCell;
	};
	function clickRowHuddler({ id, rowIndex }: any) {

		if (openInfoModalIfClickOnRow) {
			const currentRow = rows.find((row: any) => row.id === id)
			dispatch(
				changeShowModal({
					nameModal: 'facilityBookingInfo',
					stateModal: true,
					otherParams: { props: currentRow },
				}),
			);
		}
		if (rowClickable && tableName !== "Help" && tableName !== "Notifications") {
			dispatch(
				setCurrentTableInfo({
					currentIndexRow: rowIndex,
					currentPage: currentPage,
				})
			);
			const path = redirectOnDetailPage
				? `${pathName}/${id}/${redirectOnDetailPage}`
				: `${pathName}/${id}`;
			historyDom.push(path);
		}
	}

	const getRemoteRows = (requestedSkip: any, take: any) => {
		dispatch(StartLoading({ requestedSkip, tableName }));
	};
	return (
		<>
			{title && (
				<div className={s.title}>
					<span>{title} </span>
					<div className={s.members_info}>
						{tableName === "Members" && (
							<>
								{countOfActiveUsers < 0 ? (
									<div
										className={s.members_btn}
										onClick={() => showActiveMembers()}
									>
										<Eye />
										<span>Show active users</span>
									</div>
								) : (
									<div className={s.members_count}>
										{`Active: ${countOfActiveUsers}`}
									</div>
								)}
							</>
						)}
					</div>
				</div>
			)}
			<div
				ref={wrapper}
				className={`${s.table__wrapper} ${customStyle && customStyle}`}
			>
				<div className={s.table_container}>
					<Grid rows={rows} columns={dataRows}>
						<SortingState
							defaultSorting={sorting}
							columnExtensions={sortingStateColumnExtensions}
							onSortingChange={setSorting}
						/>
						{/* <VirtualTableState
                infiniteScrolling
                loading={loading}
                totalRowCount={totalCount}
                pageSize={25}
                skip={skip}
                getRows={getRemoteRows}
              /> */}

						<PagingState
							currentPage={currentPage}
							onCurrentPageChange={setCurrentPage}
							pageSize={pageSize}
						/>
						<CustomPaging totalCount={totalRowCount} />

						<VirtualTable
							height={tableHeight}
							estimatedRowHeight={52}
							columnExtensions={tableColumnExtensions}
							rowComponent={(props) => customRowComponent(props)}
							cellComponent={(props) => customCellComponent(props)}
						/>
						{tableName !== "Help" && <TableHeaderRow showSortingControls />}

						<PagingPanel />
						{/* <PagingPanel containerComponent={(e) => {
                let firstCount;
                let secondCount;
                const array = new Array(e.totalPages).fill(null);
  
                if (e.currentPage === 0) {
                  firstCount = 1
                } else {
                  firstCount = e.currentPage * 50 + 1
                }
  
                if (e.currentPage + 1 === e.totalPages) {
                  secondCount = e.totalCount
                } else if (e.currentPage === 0) {
                  secondCount = e.pageSize
                } else {
                  secondCount = (e.currentPage + 1) * e.pageSize
                }
                return (
                  <div className={s.pagination__block}>
                    <div className={s.pagination}>
                      <span className={s.pagination_page_number}>
                        {firstCount}-{secondCount} of {e.totalCount}
                      </span>
                    </div>
  
                    <button onClick={() => e.onCurrentPageChange(e.currentPage - 1)} className={s.pagination_prev} tabIndex={-1} type="button" disabled={e.currentPage === 0 ? true : false}>
                      <svg className={s.pagination_arrow} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>
                    </button>
                    <div className={s.pagination__wrapper}>
                      {
                        array.map((item, index) => {
                          return (
                            <button onClick={() => e.onCurrentPageChange(index)} key={`pagItem-${index}`} className={`${s.pagination_item} ${e.currentPage === index ? s.active : ''}`}>
                              {index + 1}
                            </button>
                          )
                        })
                      }
                    </div>
                    <button onClick={() => e.onCurrentPageChange(e.currentPage + 1)} className={s.pagination_next} tabIndex={0} type="button" disabled={e.currentPage + 1 >= e.totalPages ? true : false}>
                      <svg className={s.pagination_arrow} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
                    </button>
                  </div>
                )
              }} /> */}
					</Grid>
				</div>
			</div>
		</>
	);
};
