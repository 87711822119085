import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { addSite } from "../../../api";
import { AddSiteForm } from "../../../components/Forms/AddSite/AddSiteForm";
import { changeShowModal } from "../../../store/actions/SideMenu";
import axiosInstance from "../../../utils/axios";
import { popUp } from "../../../utils/helpersFunction";
import s from "./AddSite.module.scss";

export type siteDataT = {
  name: string;
  code: string;
  braintree_merchant_id: string;
  database_id: string;
  bank_code: string;
  email: string;
};

export const AddSite = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initValues = {
    name: "",
    code: "",
    database_id: "",
    braintree_merchant_id: "",
    bank_code: "",
    email: "",
  };

  const onSubmit = (history: any) => (values : any) => {
    // console.log(values)
    // values.status = values.status.value;
    // console.log(values)
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    const axios = axiosInstance(history);
    addSite(axios)(values)
      .then(async (res) => {
        const siteData = await res.data;
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        dispatch(
          changeShowModal({
            nameModal: "newSite",
            stateModal: true,
            otherParams: siteData,
          })
        );
      })
      .catch((...e: any) => {
        popUp({ props: e });
        dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
        new Error("Error save Site ");
      });
  };

  return (
    <div>
      <div className={s.title}>Add New Site</div>
      <div className={s.boxForm}>
        <AddSiteForm initValues={initValues} onSubmit={onSubmit(history)} />
      </div>
    </div>
  );
};
