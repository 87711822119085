import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { changeShowModal } from "../../../store/actions/SideMenu";
import { Button } from '../../FormComponents/Button/Button';
import s from '../style/modalStyle.module.scss';

export const ModalUpdateFeedback = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const backToFeedbackPage = () => {
        history.push(`/feedbacks`);
        dispatch(changeShowModal({ 'nameModal': "updateFeedback", 'stateModal': false, otherParams: undefined }))
    }
    
    return (
        <div className={s.modalBox}>
            <div className={s.title}> Done!</div>
            <div className={s.description}>Feedback was successfully updated</div>
            <Button text="Back to Feedback list" type="submit" class_type="secondary" onClick={() => backToFeedbackPage()} />
        </div>
    )
}